import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, PanelLabel } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdHelpOutline, MdHelp, MdChevronRight } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow, FinancesTableRowREI } from '../../components/CustomAccordion'
import Sidebar from '../../components/Sidebar'
import { IRR } from '@formulajs/formulajs'
import { GumroadAd } from '../../components/GumroadAd'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query PropertyRentalCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 48px;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
    background-color: ${props => props.theme.theme.bg.primary};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: auto 0px auto 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const InlineLabel = styled.span`
  font-size: 0.9rem;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  font-weight: 500;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const SectionLabel = styled.h3`
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0 12px 0px 0;
  font-weight: 500;
  display: flex;
  flex-wrap: nowrap;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;

  @media (max-width: 500px) {
    margin: 0 0 8px 0;
  }
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  margin: 0 0px 0px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }

  &.no-border {
    border: none;
  }

  &.shaded-bg-tertiary {
    background-color: ${(props) => props.theme.theme.bg.tertiary};
  }

  &.shaded-bg-inset {
    background-color: ${(props) => props.theme.theme.bg.inset};
  }
`;

const InputPanel = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 4px;
  align-content: center;

  background-color: ${(props) => props.theme.theme.bg.tertiary};

  .suffix {
    display: flex;
    align-self: center;
  }

  @media (max-width: 500px) {
    grid-template-columns: auto;
  }
`

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`;

const InputGroup = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */

  @media (max-width: 500px) {
    padding: 24px 12px 0 12px;
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  width: auto;
  justify-content: flex-start;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const ResultsTable = styled.div`
display: grid;
grid-template-columns: auto auto;
align-items: center;
align-content: start;
border: 2px solid ${(props) => props.theme.theme.colors.transparentGreen};
border-radius: 4px;


div {
  padding: 4px;
}

div:nth-child(4n), div:nth-child(4n-1){
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
}

`

const ResultsColumn = styled.div`
  margin: 12px 24px 0 0;

  h3 {
    margin: 0 0 12px 0;
  }


`

const ColumnsWrapper = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) ) ;
  grid-gap: 24px;
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

const HorizontalLine = styled.div`
  border: 0.5px solid ${ props => props.theme.theme.border.primary};
  margin: 24px 0 24px ;
  width: 100%;
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const InputSectionTitle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0 0 12px 0;
`

const VerticallyCenteredText = styled.div`
  display: flex;
  align-self: center;
`

const ScrollLabel = styled.div`
  margin: 12px;
  display: flex;
  align-items: center;
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month #: </strong>
          {payload[0].payload.month}
        </CardText400>

        <CardText400>
          <strong>Monthly payment: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.monthlyPayment.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards interest: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards principal: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.principalPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Loan Balance: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.loanBalance.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RentalPropertyCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  function NumberFormatYears(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  }

  function NumberFormatPerYear(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        prefix="$"
        suffix=" / year"
      />
    );
  }

  function NumberFormatAnnualIncrease(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        suffix="% annual increase"
      />
    );
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ interestRate, setInterestRate ] = useState(6)
  const [ purchasePrice, setPurchasePrice ] = useState(300000)
  const [ downPayment, setDownPayment ] = useState(20)
  const [ loanTerm, setLoanTerm ] = useState(30)
  const [ closingCosts, setClosingCosts ] = useState(7000)
  const [ rehabCosts, setRehabCosts ]= useState(0)

  const [ monthlyRent, setMonthlyRent ] = useState(2500)
  const [ annualRentIncrease, setAnnualRentIncrease ] = useState(3)
  const [ propertyManagementFee, setPropertyManagementFee ] = useState(10)
  const [ vacancyRate, setVacancyRate ] = useState(8)

  const [ annualPropertyTaxes, setAnnualPropertyTaxes] = useState(2000)
  const [ annualPropertyTaxesIncrease, setAnnualPropertyTaxesIncrease] = useState(3)
  const [ annualInsurance, setAnnualInsurance] = useState(1300)
  const [ annualInsuranceIncrease, setAnnualInsuranceIncrease] = useState(3)
  const [ annualHOAFees, setAnnualHOAFees] = useState(0)
  const [ annualHOAFeesIncrease, setAnnualHOAFeesIncrease] = useState(3)
  const [ annualMaintenance, setAnnualMaintenance] = useState(3000)
  const [ annualMaintenanceIncrease, setAnnualMaintenanceIncrease] = useState(3)
  const [ annualOtherExpenses, setAnnualOtherExpenses] = useState(0)
  const [ annualOtherExpensesIncrease, setAnnualOtherExpensesIncrease] = useState(3)

  const [ salePrice, setSalePrice] = useState(600000)
  const [ holdingPeriod, setHoldingPeriod] = useState(20)
  const [ saleExpenses, setSaleExpenses] = useState(9)

  const downPaymentValue = purchasePrice * ( downPayment / 100)
  const principal = parseFloat(purchasePrice) - parseFloat(downPaymentValue)
  const initialInvestment = parseFloat(downPaymentValue) + parseFloat(closingCosts) + parseFloat(rehabCosts)

  const annualPaymentPeriods = 12
  const rate = (interestRate/100) / annualPaymentPeriods

  const nper = annualPaymentPeriods * loanTerm
  const payment = principal * ( ( rate * Math.pow( 1 + rate, nper) ) / ( Math.pow( 1 + rate, nper) - 1) )

  console.log(payment)

  const calcInvestmentData = () => {
    let investmentData = []
    for(let i = 0; i <= holdingPeriod; i++){

      if(!i){

        // T0 data 

        console.log(initialInvestment)

        investmentData.push({
          year: i,
          annualIncome: 0,
          annualMortgagePayments: 0,
          annualExpenses: 0,
          annualCashFlow: -(initialInvestment) ,
          annualCoCReturn: 0,
          interestPortion: 0,
          principalPortion: 0,
          loanBalance: parseFloat(principal),
          totalEquity: downPaymentValue,
          NOI: 0
        })

      } else {
        const annualIncome = 12 * monthlyRent* Math.pow(1 + (annualRentIncrease/100), i-1) * ( 1 - ( propertyManagementFee/100) - ( vacancyRate / 100 ))
        const annualMortgagePayments = 12 * payment
        const annualExpenses = annualPropertyTaxes * Math.pow(1 + (annualPropertyTaxesIncrease/100), i-1) +
          annualInsurance * Math.pow(1 + (annualInsuranceIncrease/100), i-1) +
          annualHOAFees * Math.pow(1 + (annualHOAFeesIncrease/100), i-1) +
          annualMaintenance * Math.pow(1 + (annualMaintenanceIncrease/100), i-1) +
          annualOtherExpenses * Math.pow(1 + (annualOtherExpensesIncrease/100), i-1)
        let annualCashFlow = 0
        const annualCashFlowAdjusted = annualIncome - annualMortgagePayments - annualExpenses
        const interestPaid = investmentData[i-1].loanBalance * (interestRate/100)
        const principalGained = annualMortgagePayments - interestPaid
        const loanBalance = investmentData[i-1].loanBalance - principalGained
        const totalEquity = purchasePrice - loanBalance
        const NOI = annualIncome - annualExpenses

        if(i == holdingPeriod){
          annualCashFlow = annualIncome - annualMortgagePayments - annualExpenses + ((salePrice * (1- (saleExpenses/100) ) ) -  loanBalance)
        } else {
          annualCashFlow = annualIncome - annualMortgagePayments - annualExpenses
        }

        investmentData.push({
          year: i,
          annualIncome: annualIncome,
          annualMortgagePayments: annualMortgagePayments,
          annualExpenses: annualExpenses,
          annualCashFlow:  annualCashFlow,
          annualCoCReturn: annualCashFlowAdjusted / initialInvestment,
          interestPortion: interestPaid,
          principalPortion: principalGained,
          loanBalance: loanBalance,
          totalEquity: totalEquity,
          NOI: NOI
        })

      }
    }
    return investmentData
  }

  const investmentData = calcInvestmentData()
  const sumAnnualIncome = investmentData.reduce( (prev, next) => ( prev + next.annualIncome ), 0)
  const sumAnnualMortgagePayments = investmentData.reduce( (prev, next) => ( prev + next.annualMortgagePayments ), 0)
  const sumAnnualExpenses = investmentData.reduce( (prev, next) => ( prev + next.annualExpenses ), 0)
  const sumAnnualCashFlow = investmentData.reduce( (prev, next) => ( prev + next.annualCashFlow ), 0)
  const sumNOI = investmentData.reduce( (prev, next) => ( prev + next.NOI ), 0)
  const totalProfit = sumAnnualCashFlow


  console.log(investmentData)

  const cashflowsArray = investmentData.map(year => year.annualCashFlow)

  console.log(cashflowsArray)

  const IRRvalue = IRR(cashflowsArray, 0.1)
  const IRRpercent = (IRRvalue * 100).toFixed(2)
  const initialCapRate = (100 * (investmentData[1].NOI / purchasePrice) ).toFixed(2)
  const initialCashFlow = investmentData[1].annualCashFlow
  const initialNOI = investmentData[1].NOI
  const initialCoC = investmentData[1].annualCoCReturn * 100
  const propertyValueCAGR = 100 * ( Math.pow( (salePrice / purchasePrice), (1/holdingPeriod) ) - 1 )
        
  console.log(initialCoC)

  return (
    <Layout>
      <SEO
        title={'Rental Property Calculator for Real Estate Investing'}
        description={'Analyze key financial metrics like IRR, Cap Rate and Cash-On-Cash return for your next real estate deal'}
        keywords={site.keywords}
      />
      <CustomReactTooltip />
      <ContainerMain>
        <h1>Rental Property Calculator for Real Estate Investing</h1>
        <H2>Analyze key financial metrics like IRR, Cap Rate and Cash-On-Cash return for your next real estate deal</H2>
      </ContainerMain>

      <CalculatorPanel>
        <CalculatorGrid>
          <form>
            <InputGroup>
            <InputSectionTitle>
              <SectionLabel>Property Purchase</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Purchase Price
                  <MdHelp
                    size="1.2rem"
                    data-tip={`Purchase price is the price you pay to buy the property, not including closing costs.`}
                  />
                </Label>
                <InputItem style={{ width: "150px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={purchasePrice}
                  onChange={event => setPurchasePrice(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Down Payment

                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the percentage of the purchase price you pay out-of-pocket.`}
                  />
                </Label>

                <InputItem style={{ width: "75px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={downPayment}
                  onChange={event => setDownPayment(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  />

                </InputItem>

                <VerticallyCenteredText>
                  <NumberFormat
                    displayType={"text"}
                    value={downPaymentValue.toFixed(0)}
                    thousandSeparator
                    prefix="$"
                  />
                </VerticallyCenteredText>
              </InputPanel>
            </FlexInput>


              <FlexInput style={{ width: "250px" }} >
                <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Mortgage Amount
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the purchase price less the down payment.`}
                      />  
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={principal.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
              </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Interest Rate
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the fixed interest rate on your loan which you would pay off over the loan duration.`}
                  />
                </Label>
                <InputItem style={{ width: "75px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={interestRate}
                  onChange={event => setInterestRate(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "200px" }} >
                <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Loan Duration
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the length of time over which you will pay off the loan.`}
                      />  
                    </Label>
                    <span className="result-value">{loanTerm} years
                    </span>
                  </AllocationGroupGray>
              </FlexInput>

            {/* <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Loan Duration
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the length of time over which you will pay off the loan.`}
                  />
                </Label>
                
                <InputItem >
                  <ThemedTextarea style={{ width: "50px" }}
                  required
                  id="outlined"
                  value={loanTerm}
                  onChange={event => setLoanTerm(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatYears,
                  }}
                  />
                  <span className='suffix'>years</span>

                </InputItem>


              </InputPanel>
            </FlexInput> */}

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Closing Costs
                  <MdHelp
                    size="1.2rem"
                    data-tip={`These are the processing fees that you pay to your lender to obtain your mortgage. These are typically 3% to 6% of the loan value.`}
                  />
                </Label>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={closingCosts}
                  onChange={event => setClosingCosts(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Rehab Costs
                  <MdHelp
                    size="1.2rem"
                    data-tip={`These are the costs of any improvements that you make to the property after purchasing it and before you rent it out.`}
                  />
                </Label>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={rehabCosts}
                  onChange={event => setRehabCosts(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>

            </InputGroup>

            <InputSectionTitle>
              <SectionLabel>Income</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <InputGroup>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <Label>
                    Monthly Rent
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This the total value of rent that is collected from your tenants each month. 
                      This does not include property management fees.`}
                    />
                  </Label>
                  <InputItem style={{ width: "100px" }}>
                    <ThemedTextarea
                    required
                    id="outlined"
                    value={monthlyRent}
                    onChange={event => setMonthlyRent(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                  </InputItem>

                  <InputItem style={{ width: "150px" }}>
                  <ThemedTextarea
                  id="outlined"
                  label="Annual Increase"
                  value={annualRentIncrease}
                  onChange={event => setAnnualRentIncrease(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  />

                </InputItem>

                </InputPanel>
              </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Property management fee
                  <MdHelp
                    size="1.2rem"
                    data-tip={`Property managers typically charge 10% of the monthly rent as a fee if you choose to use property management.`}
                  />
                </Label>
                <InputItem style={{ width: "75px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={propertyManagementFee}
                  onChange={event => setPropertyManagementFee(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>
              

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <Label>
                  Vacancy rate
                  <MdHelp
                    size="1.2rem"
                    data-tip={`You should budget for some amount of vacancy due to tenant turnover. One month of vacancy per year implies an 8% vacancy rate.`}
                  />
                </Label>
                <InputItem style={{ width: "75px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={vacancyRate}
                  onChange={event => setVacancyRate(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>

            </InputGroup>

            <InputSectionTitle>
              <SectionLabel>Yearly Expenses</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <InputGroup>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <Label>
                    Property Taxes
                    <MdHelp
                      size="1.2rem"
                      data-tip={`State property tax rates as a percentage of property value range from 0.28% in Hawaii to 2.49% in New Jersey.`}
                    />
                  </Label>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualPropertyTaxes}
                    onChange={event => setAnnualPropertyTaxes(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                  
                    <span className='suffix'>per year</span>
                  
                  </InputItem>


                  <InputItem style={{ width: "175px" }}>
                    <ThemedTextarea
                    id="outlined"
                    label="Annual Increase"
                    value={annualPropertyTaxesIncrease}
                    onChange={event => setAnnualPropertyTaxesIncrease(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    />
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <Label>
                    Insurance
                    <MdHelp
                      size="1.2rem"
                      data-tip={`The national average for landlord insurance is $1,288 per year.`}
                    />
                  </Label>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualInsurance}
                    onChange={event => setAnnualInsurance(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>


                  <InputItem style={{ width: "175px" }}>
                    <ThemedTextarea
                    id="outlined"
                    label="Annual Increase"
                    value={annualInsuranceIncrease}
                    onChange={event => setAnnualInsuranceIncrease(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    />
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <Label>
                    HOA Fees
                    <MdHelp
                      size="1.2rem"
                      data-tip={`Apartments, condos, and some single family homes have HOA fees. These fees will vary by 
                      property and locations but typically range between $200 and $300 per month.`}
                    />
                  </Label>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualHOAFees}
                    onChange={event => setAnnualHOAFees(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>


                  <InputItem style={{ width: "175px" }}>
                    <ThemedTextarea
                    id="outlined"
                    label="Annual Increase"
                    value={annualHOAFeesIncrease}
                    onChange={event => setAnnualHOAFeesIncrease(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    />
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <Label>
                    Maintenance
                    <MdHelp
                      size="1.2rem"
                      data-tip={`A common rule of thumb is to budget for 1% of your 
                      property’s value to be spent each year on maintenance.`}
                    />
                  </Label>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualMaintenance}
                    onChange={event => setAnnualMaintenance(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>


                  <InputItem style={{ width: "175px" }}>
                    <ThemedTextarea
                    id="outlined"
                    label="Annual Increase"
                    value={annualMaintenanceIncrease}
                    onChange={event => setAnnualMaintenanceIncrease(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    />
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <Label>
                    Other expenses
                    <MdHelp
                      size="1.2rem"
                      data-tip={`Other expenses could include utilities like water, sewer, gas, or 
                      trash collection or things like landscaping or snow removal.`}
                    />
                  </Label>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualOtherExpenses}
                    onChange={event => setAnnualOtherExpenses(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>


                  <InputItem style={{ width: "175px" }}>
                    <ThemedTextarea
                    id="outlined"
                    label="Annual Increase"
                    value={annualOtherExpensesIncrease}
                    onChange={event => setAnnualOtherExpensesIncrease(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    />
                  </InputItem>
                </InputPanel>
              </FlexInput>
            
            </InputGroup>

            <InputSectionTitle>
              <SectionLabel>Sale</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <InputGroup>

              <FlexInput style={{ width: "auto" }}>
                  <InputPanel>
                    <Label>
                      Sale price
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the price that you expect to sell the property at the end of the holding length.`}
                      />
                    </Label>
                    <InputItem style={{ width: "135px" }}>
                      <ThemedTextarea
                      required
                      id="outlined"
                      value={salePrice}
                      onChange={event => setSalePrice(event.target.value)}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      />
                    </InputItem>

                    <VerticallyCenteredText>
                      <NumberFormat
                        displayType={"text"}
                        value={propertyValueCAGR.toFixed(2)}
                        thousandSeparator
                        suffix="% annual growth rate"
                      /> 
                    </VerticallyCenteredText>
                  </InputPanel>
                </FlexInput>
            
                <FlexInput style={{ width: "auto" }}>
                  <InputPanel>
                    <Label>
                      Holding length
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the length of time in years that you plan to keep the property as a rental before selling it.`}
                      />
                    </Label>
                    <InputItem >
                      <ThemedTextarea style={{ width: "50px" }}
                      required
                      id="outlined"
                      value={holdingPeriod}
                      onChange={event => setHoldingPeriod(event.target.value)}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatYears,
                      }}
                      />
                      <span className='suffix'>years</span>
                    </InputItem>


                  </InputPanel>
                </FlexInput>

                <FlexInput style={{ width: "auto" }}>
                  <InputPanel>
                    <Label>
                      Sale expenses
                      <MdHelp
                        size="1.2rem"
                        data-tip={`These depend on the value of your property and typically total 8 to 10% of the sale price.`}
                      />
                    </Label>
                    <InputItem style={{ width: "75px" }}>
                      <ThemedTextarea
                      required
                      id="outlined"
                      value={saleExpenses}
                      onChange={event => setSaleExpenses(event.target.value)}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatPercentage,
                      }}
                      />
                    </InputItem>
                  </InputPanel>
                </FlexInput>


            </InputGroup>

            <InputSectionTitle>
              <SectionLabel>Deal Analysis</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <ResultsPanel>
              <ResultsColumn>
                <SectionLabel>First year breakdown</SectionLabel>
                <ResultsTable>
                  <div></div>
                  <div>Annual</div>
                  <div>Income</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={(12*monthlyRent)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Mortgage Payments</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={(12*payment).toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Vacancy ({vacancyRate}%)</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={(12*monthlyRent*(vacancyRate/100))}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Management Fee ({propertyManagementFee}%)</div>
                  <div>
                  <span>
                      <NumberFormat
                        displayType={"text"}
                        value={(12*monthlyRent*(propertyManagementFee/100))}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Property tax</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={annualPropertyTaxes}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Insurance</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={annualInsurance}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>HOA Fees</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={annualHOAFees}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Maintenance</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={annualMaintenance}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Other costs</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={annualOtherExpenses}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div><strong>Cash Flow</strong></div>
                  <div>
                    <strong>
                      <NumberFormat
                        displayType={"text"}
                        value={initialCashFlow.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </strong>
                  </div>
                  <div><strong>Net Operating Income (NOI)</strong></div>
                  <div>
                    <strong>
                      <NumberFormat
                        displayType={"text"}
                        value={initialNOI.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </strong>
                  </div>

                  <div>
                    <span>
                      <strong>
                        Capitalization rate (cap rate):
                      </strong>
                    </span>
                  </div>

                  <div>
                    <span>
                      <strong>
                        {initialCapRate}%
                      </strong>
                    </span>
                  </div>

                  <div>
                    <span>
                      <strong>Cash-on-Cash Return</strong>
                    </span>
                  </div>

                  <div>
                    <span>
                      <strong>
                        <NumberFormat
                          displayType={"text"}
                          value={initialCoC.toFixed(2)}
                          thousandSeparator
                          suffix="%"
                        />
                      </strong>
                    </span>
                  </div>

                </ResultsTable>

              </ResultsColumn>

              <ResultsColumn>
                <SectionLabel>Over the {holdingPeriod} year holding length</SectionLabel>
                <ResultsTable>
                  <div>
                    <span>
                      <strong>
                        Return (IRR):
                      </strong>
                    </span>
                  </div>

                  <div>
                    <span>
                      <strong>
                        {IRRpercent}%
                      </strong>
                    </span>
                  </div>

                  <div>
                    <span>
                      <strong>
                        Total profit after sale:
                      </strong>
                    </span>
                  </div>

                  <div>
                    <span>
                      <strong>
                        <NumberFormat
                          displayType={"text"}
                          value={totalProfit.toFixed(0)}
                          thousandSeparator
                          prefix="$"
                        />
                      </strong>
                    </span>
                  </div>

                  <div>
                    <span>
                      Total rental income:
                    </span>
                  </div>

                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={sumAnnualIncome.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>

                  <div>
                    <span>
                      Total mortgage payments:
                    </span>
                  </div>

                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={sumAnnualMortgagePayments.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>

                  <div>
                    <span>
                      Total expenses:
                    </span>
                  </div>

                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={sumAnnualExpenses.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>  

                  <div>
                    <span>
                      Total net operating income:
                    </span>
                  </div>

                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={sumNOI.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>

                </ResultsTable>
              </ResultsColumn>


            </ResultsPanel>

          </form>

        </CalculatorGrid>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Annual breakdown over {holdingPeriod} years </AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <ScrollLabel>
                <span>
                  Scroll to reveal more
                </span>
                <MdChevronRight
                  size="1.2rem"
                />
              </ScrollLabel>

              <FinancesTableGrid>
                <FinancesTableRowREI className="labels-row">
                  <span>Year</span>
                  <span>Annual Income</span>
                  <span>Annual Mortgage Payments</span>
                  <span>Annual Expenses</span>
                  <span>Annual Cash Flow</span>
                  <span>Cash-on-cash return</span>
                  <span>Loan Balance</span>
                  <span>Total Equity</span>
                  <span>Net Operating Income</span>
                </FinancesTableRowREI>

                {investmentData.map((dataPoint, index) => {
                  if (!index) {
                    return (
                      <FinancesTableRowREI>
                        <span>{dataPoint.year}</span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.annualCashFlow.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span></span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.totalEquity.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span></span>
                      </FinancesTableRowREI>
                    );
                  } else {
                    return (
                      <FinancesTableRowREI>
                        <span>{dataPoint.year}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.annualIncome.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.annualMortgagePayments.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.annualExpenses.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.annualCashFlow.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={(100 * dataPoint.annualCoCReturn).toFixed(2)}
                            thousandSeparator
                            suffix="%"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.totalEquity.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.NOI.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRowREI>
                    );
                  }
                })}

              <FinancesTableRowREI className="labels-row">
                <span>Total</span>
                <span>
                  <NumberFormat
                    displayType={"text"}
                    value={ sumAnnualIncome.toFixed(0) }
                    thousandSeparator
                    prefix="$"
                  />
                </span>
                <span>
                  <NumberFormat
                    displayType={"text"}
                    value={ sumAnnualMortgagePayments.toFixed(0) }
                    thousandSeparator
                    prefix="$"
                  />
                </span>
                <span>
                  <NumberFormat
                    displayType={"text"}
                    value={ sumAnnualExpenses.toFixed(0) }
                    thousandSeparator
                    prefix="$"
                  />
                </span>
                <span>
                  <NumberFormat
                    displayType={"text"}
                    value={ sumAnnualCashFlow.toFixed(0) }
                    thousandSeparator
                    prefix="$"
                  />
                </span>
                <span></span>
                <span></span>
                <span></span>
                <span>
                  <NumberFormat
                    displayType={"text"}
                    value={ sumNOI.toFixed(0) }
                    thousandSeparator
                    prefix="$"
                  />
                </span>

              </FinancesTableRowREI>

              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

      </CalculatorPanel>

      <GumroadAd />

      <ContainerTwoCol>

        <ContainerContent>

          <h2>Real estate investing overview</h2>

          <p>
            Real estate investing is a tried and true way to build wealth and is considered to be a key pillar of any 
            diversified investing portfolio. Here are a few reasons why you should consider real estate as part of your long-term investing strategy:

            <ul>
              <li>
                Real estate is relatively <strong>less volatile</strong> compared to equities and bonds.
              </li>
              <li>
                Real estate helps to <strong>diversify</strong> your portfolio because it has a low or in some cases 
                even negative correlation with other major assets classes like stocks.
              </li>
              <li>
                Real estate is an excellent <strong>inflation hedge</strong>. As the economy grows, demand for real estate grows as well, 
                driving rents higher and increasing the value of your property. 
              </li>
              <li>
                Compared to other asset classes, real estate allows investors to use much <strong>more leverage</strong> thanks to a special financing method: 
                the mortgage. This means that you can control the entire asset and capture all appreciation while only paying a fraction 
                of the value of the property, with typical down payments ranging from 3.5% to 20% of the property’s purchase price. 
                Leverage is a double-edged sword however, because when the value of property goes down, your losses are also 
                amplified and your equity in the property can become quickly wiped out. A mortgage is said to be underwater when the 
                current market value of the property is less than the original principal on the mortgage. Depending on how much 
                the value of the property has decreased, it's even possible to have negative equity, which makes it 
                difficult to refinance or sell the property. 
              </li>
            </ul>
          </p>
          <p>
            This interactive calculator allows you to input the details of your real estate property deal and automatically 
            calculate key real estate investing metrics like <strong>monthly cashflow</strong>, <strong>cash-on-cash return</strong>, <strong>IRR</strong>, 
            and <strong>cap rate</strong> shown in the <em>Deal Analysis</em> section.
          </p>

          <p>
            You can click on <strong>Annual breakdown over 20 years</strong> to view the projected cash flow and cash-on-cash return for each year you 
            hold the property.
          </p>

          <h3>Some things to keep in mind</h3>

          <p>
            It’s important to understand that the metrics from this calculator are projections based on your inputs, and investment 
            performance is not guaranteed. Make sure to do your due diligence when evaluating an investment property and understand 
            any risks that could be unique to that property or real estate market. 
          </p>

          <p>
            Investing in rental properties is not completely passive income - it requires you to put in time, energy, and money as a 
            landlord to manage tenants and maintain the property. Many landlords will use a property management company to handle 
            the day-to-day responsibilities of running your rental, but this does come with a management fee, usually around 10% of 
            collected rent. Property management can be especially helpful for landlords who don’t live near the property or who have limited time.
          </p>

          <h2>Deal Analysis: understanding the key financial metrics for real estate investing</h2>

          <p>
            In the <em>Deal Analysis</em> section, this calculator automatically runs the numbers on several key real estate financial metrics that 
            you can use to analyze if this property is worth your investment.
          </p>

          <h3>Cash Flow</h3>

          <blockquote>
            <strong>Monthly cash flow</strong> = <em>rental income</em> - <em>mortgage payment</em> - <em>all operating expenses</em>
          </blockquote>

          <p>In general, a higher cash flow is better, but investors may be willing to accept a lower cashflow to own a property that has a higher appreciation potential.</p>

          <h3>Net Operating Income</h3>

          <blockquote>
              <strong>Net operating income (NOI)</strong> = <em>rental income</em> - <em>all reasonably necessary operating expenses</em>. 
          </blockquote>


          <h3>Capitalization rate (cap rate)</h3>

          <blockquote>
            <strong>Cap rate</strong> = NOI / property value
          </blockquote>

          <p>
            The <strong>cap rate</strong> is considered to be among the best metrics to forecast the return-on-investment from a property and compare 
            it to other properties. Knowing the property’s cap rate helps you to estimate how long it will take to recoup your 
            initial investment into the property and also indicates the level of risk involved with the property. 
            Cap rates are correlated with risk, so in general the higher the cap rate, the more risk you are taking on by investing in the property. 
          </p>

          <p>
            This calculator uses the property’s NOI for the first year divided by the purchase price to calculate the cap rate.
          </p>

          <p>
            There is no universal rule for what is a good or bad cap rate in the real estate industry. There are many factors that impact cap rate including 
            size of the market, the location, and property type. Some investors prefer higher cap rates in the 8%+ range, 
            knowing that they are taking on higher risk. In general, around a 5% cap rate is optimal and strikes a balance between risk and return. 
          </p>

          <h3>Cash-on-cash return</h3>

          <blockquote>
            <strong>Cash-on-cash return</strong> = annual cash flow / cash invested into property.
          </blockquote>

          <p>
            <strong>Cash-on-cash return</strong>, or put simply the cash yield, is another key metric that tells investors the annual cash yield relative 
            to the upfront cash invested in the deal. Cash-on-cash return is useful because it takes into account the long-term debt 
            used to finance the property purchase, unlike the cap rate which is independent of financing. 
          </p>

          <p>
            If a property is purchased in cash with no financing, then the cash-on-cash return is the same as the cap rate.
          </p>

          <p>
            What is considered a good cash-on-cash return depends on the local market and your expectations for future 
            appreciation as an investor. Higher is generally better with cash-on-cash returns, and some investors may be 
            happy with 5% to 10% cash-on-cash returns while others may target 10%+.  
          </p>

          <p>
            You can click on <strong>Annual breakdown over 20 years</strong> to expand a table that shows the cash-on-cash return for the property over each 
            year during the holding period. If your annual cash flow increases over time (based on your annual increase 
            numbers for each input), then cash-on-cash return will also increase over time because the upfront cash 
            investment in the deal is unchanged.
          </p>

          <h3>IRR (Internal rate of return or simply return)</h3>

          <p>
            <strong>Internal rate of return</strong>, or simply <strong>IRR</strong> or return, is a very important metric which measures the all-in 
            annualized return based on all projected cashflows over the property’s holding period. 
          </p>

          <p>
            IRR is calculated by setting the Net Present Value in a discounted cash flow formula to 0 and then solving
            for the discount rate. IRR is difficult to calculate manually, which is where this calculator comes in. 
          </p>

          <p>
            While IRR and Return-on-investment (ROI) are similar metrics, IRR is generally preferred by real estate 
            investors because it incorporates the time value of money into the annualized return, while ROI does not. 
            ROI only measures the return on the investment after sale relative to the cost, and does not take into 
            account your income while holding the property. 
          </p>

          <p>
            Knowing the IRR is very useful because it allows you to compare the projected returns from this property 
            investment with other properties and even non-real estate investments like stocks or private equity deals. 
          </p>

          <p>
            In general, a higher IRR is better than a lower IRR when all other factors are equal. Be mindful that IRR does not capture investment risk.
          </p>

          <h3>Total profit after sale</h3>

          <p>
            The <strong>total profit after sale</strong> is the sum of all cash flows over the time that you own the property. 
            Total profit includes your gains from selling the property and it factors in your sale expenses as well as the 
            initial investment you put into the property when you purchased it.
          </p>

        <RelatedPagesPanel style={{marginTop: '24px'}}>
          <PanelLabel>Related Pages</PanelLabel>
          <RelatedPagesGroup>
            <Link to='/tools/15-vs-30-year-mortgage-calculator'> 15 Year vs 30 Year Mortgage Calculator</Link>
            <Link to='/tools/house-hacking-calculator'>House Hacking Calculator</Link>
          </RelatedPagesGroup>
        </RelatedPagesPanel>

          <h2>Property purchase inputs</h2>
          
          <p>
            <strong>Purchase price</strong> is the price you pay to buy the property, not including closing costs.
          </p>

          <p>
            <strong>Down payment</strong> is the percentage of the purchase price you pay out-of-pocket. 
            The typical down payment for investment properties is 20 to 25%, but for owner-occupied real estate, 
            you may be able to put down as little as 3.5% with an FHA loan. If you’re buying the property in cash, 
            then you should set this value to 100%.
          </p>

          <p>
            <strong>Interest rate</strong> is the fixed interest rate on your loan which you would pay off over the <em>loan duration</em>. 
            As of November 2022, the current average rate for a 30 year fixed mortgage in the USA was 7%.
          </p>

          <p>
            <strong>Loan duration</strong> is the length of time over which you will pay off the loan. 
            The most common loan durations in the USA are 30-year and 15-year.
          </p>

          <p>
            <strong>Closing costs</strong> are processing fees that you pay to your lender to obtain your mortgage and 
            include things like Applications fees, Appraisal, and Loan Origination fees. 
            Closing costs are typically between 3% and 6% of your loan amount.
          </p>

          <p>
            <strong>Rehab costs</strong> are the costs of any improvements that you make to the property after purchasing it and before you rent it out.
          </p>

          <h2>Income inputs</h2>

          <p>
            <strong>Monthly rent</strong> is the total value of rent that is collected from your tenants each month. This does not include property management fees.
          </p>

          <p>
            <strong>Vacancy rate</strong> is the percentage of time when your property is unoccupied and you are not collecting rent from a tenant. 
            One month of vacancy per year implies an 8% vacancy rate.
          </p>

          <p>
            <strong>Property Management fee</strong>. If you do not manage the rental yourself, you will need to hire a property manager to do so. 
            Property managers typically charge 10% of the monthly rent as a fee. 
          </p>

          <h2>Yearly expense inputs</h2>

          <p>
            <strong>Property taxes</strong> are based on the assessed value of your property and vary by state, county, and locality that the property is located in. 
            State property tax rates as a percentage of property value range from 0.28% in Hawaii to 2.49% in New Jersey.
          </p>

          <p>
            <strong>Landlord insurance</strong> protects your property against damage and also provides personal liability coverage. 
            It protects your property against damage from natural disasters like storms, lightning, and hail as well as fire damage and damage from tenants.
            Generally landlord insurance costs about 15% more than homeowner insurance. Landlord insurance varies by geographic region as well as size, value, and age of 
            your property. The national average for landlord insurance is $1,288 per year.
          </p>

          <p>
            You will need to budget for <strong>Homeowner’s Association (HOA)</strong> fees if your property is in an HOA. 
            HOA fees are standard for condos, apartments, and single-family homes in planned communities and are used to 
            pay for amenities, property maintenance and repairs. These fees will vary by property and location but typically 
            range between $200 and $300 per month.
          </p>

          <p>
            Budgeting for <strong>maintenance</strong> of your property is important to keep it in good condition and to be prepared 
            for unexpected repairs that may come up. Neglected maintenance can often cause bigger, more expensive 
            problems down the line, so being proactive with maintenance will save you money and headaches over the long term. 
            A common rule of thumb is the <em>1% of property value formula</em>, which says that you should budget for 1% of your 
            property’s value to be spent each year on maintenance. Factors like age and condition of your property will influence 
            the amount of maintenance required.
          </p>

          <p>
            <strong>Other expenses</strong> is a bucket for any other miscellaneous expenses that you anticipate. 
            This could include utilities like water, sewer, gas, or trash collection or things like landscaping or snow removal. 
          </p>

          <h2>Sale inputs</h2>

          <p>
            <strong>Sale price</strong> is the price that you expect to sell the property at the end of the holding length. 
          </p>

          <p>
            <strong>Holding length</strong> is the length of time in years that you plan to keep the property as a rental before selling it.
          </p>

          <p>
            <strong>Sale expenses</strong> depend on the value of your property and typically total 8 to 10% of the sale price. 
            As a seller, your biggest sale expense is typically the commission to your real estate agent, with a national average 
            commission coming in at 5.8% of the property sale price. Closing costs are the second biggest sale expense at 1-3% of 
            the sale price. Closing costs are related to the transfer of ownership of the property to the buyer and include things 
            like title fees, settlement fees, and transfer taxes. You may also want to budget for repair, preparation, and staging 
            of your property to maximize your sale price.
          </p>

        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'rental-property-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default RentalPropertyCalc
