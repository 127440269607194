import React from 'react'
import styled from 'styled-components'
import EmailOptin from './EmailOptin'
import { AdPanel, SidebarPanel, SidebarGrid } from '../pages/index'
import GoogleAdWrapper from './GoogleAd'
import { MintMobileAd } from './MintMobileAd'

const Sidebar = ( props ) => {
  return (
    <SidebarGrid>

      <MintMobileAd />
      
      <AdPanel />

      <GoogleAdWrapper />

      <SidebarPanel>
        <EmailOptin />
      </SidebarPanel>
    </SidebarGrid>
  )
}

export default Sidebar
